.iauto-avail-requests-wrapper {
    background-color: #ffffff;
    border-radius: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.iauto-operator-offer-panel-header {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 16px;
    color: #333;
}

.iauto-avail-requests-item {
    background-color: #f9f9f9;
    border-radius: 0 0 8px 8px;
    padding: 12px 10px!important;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.iauto-avail-requests-item .ant-list-item-meta-title {
    color: #555;
    font-size: 16px;
    font-weight: 500;
}

.iauto-avail-requests-item .ant-list-item-meta-description {
    color: #888;
    font-size: 14px;
}
