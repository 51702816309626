.iauto-cabinet-request-container {
    display: flex;
    flex-direction: column;
}

.iauto-request-chat-loader {
    margin: 0 10px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
    .iauto-request-chat-loader {
        margin: 0;
    }

    .iauto-cabinet-request-container {
        margin-top: 20px;
    }

    .iauto-request-chat {
        margin: 0!important;
    }
}

.iauto-request-chat {
    background-color: #ffffff;
    border-radius: 10px;
    margin: 0 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.iauto-request-chat-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 650px;
    overflow-y: auto;
}