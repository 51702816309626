.iauto-app-footer {
    text-align: center;
    background-color: transparent;
    padding: 0 0 40px 0;
    bottom: 0;
    width: 100%;
}

.iauto-footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.iauto-social-buttons {
    display: flex;
    gap: 15px;
}

.iauto-social-button .anticon {
    color: #6c757d;
    font-size: 20px;
    transition: color 0.3s ease;
}

.iauto-social-button:hover .anticon {
    color: #0077b6;
}

.iauto-rights-reserved {
    font-size: 14px;
    color: #6c757d;
    font-weight: 500;
}

footer {
    text-align: center;
    font-weight: bold;
    margin-top: 1%;
    padding: 1%;
    bottom: 0;
    width: 100%;
    height: 50px;
    color: #515558;
    background-color: transparent;
}
