.iauto-app-content {
    margin-top: 2%;
    flex: 1;
}

.iauto-base-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.iauto-notification-modal {
    position: fixed;
    z-index: 1000;
}
