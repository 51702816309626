.iauto-promo-request-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.iauto-page-title {
    text-align: center;
    margin-bottom: 20px;
}

.iauto-offer-table-wrapper {
    margin-bottom: 30px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.iauto-cards-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.iauto-card {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
}

.iauto-info-block {
    text-align: center;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.iauto-info-block .ant-btn {
    margin-top: 15px;
}

.iauto-info-block .ant-typography {
    display: block;
    margin-bottom: 10px;
}
