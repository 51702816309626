.iauto-new-request-button {
    min-height: 40px;
    margin: 0 10px 10px 10px;
    font-size: 16px !important;
    font-weight: 500 !important;
    border-radius: 8px !important;
    border: none !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s !important;
}

.iauto-new-request-button:hover {
    background-color: #656565 !important;
    color: #ffffff !important;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2) !important;
    transform: translateY(-2px) !important;
}

.iauto-new-request-button:active {
    background-color: #656565 !important;
    color: #ffffff !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
    transform: translateY(0) !important;
}

@media (max-width: 800px) {
    .iauto-new-request-button {
        margin: 0 10px 20px 10px;
    }
}
