.iauto-request-chat-footer {
    margin: 2px 0 0 0;
    border-top: 1px solid #ecebeb;
    box-shadow: 0 0 5px 0 #ecebeb;
    padding: 10px;
    align-items: baseline;
    justify-content: space-between;
    display: flex;
}

.iauto-request-chat-input {
    height: 45px;
    min-height: 45px;
    display: inline;
    flex: 1;
}

.iauto-request-chat-button-block {
    display: flex;
    justify-content: center;
}