.iauto-main-container {
    padding: 20px;
}

.iauto-main-header {
    margin-bottom: 5%;
    text-align: center;
}

.iauto-card-row {
    margin-bottom: 20px;
}

.iauto-card-col {
    padding: 10px;
}

.iauto-card-basic,
.iauto-card-large {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    border-radius: 15px!important;
}

.iauto-card-basic:hover,
.iauto-card-large:hover {
    transform: translateY(-5px);
}

.iauto-card-large {
    min-height: 150px;
}

.iauto-card-basic {
    min-height: 550px;
}
