.iauto-request-chat-header {
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    padding: 2% 3%;
    border-bottom: 1px solid #ecebeb;
    box-shadow: 0 5px 10px 0 #ecebeb;
    display: flex; /* Use Flexbox to control alignment */
    align-items: center; /* Vertically center-align header content */
    justify-content: space-between; /* Space between left and right elements */
}

.iauto-create-offer-button {
    background-color: #494848;
    color: #ffffff;
    border-radius: 10px!important;
}

.iauto-request-chat-header > p {
    display: inline;
    margin-right: 10px;
}

.iauto-request-chat-header input,
.iauto-request-chat-header input:hover,
.iauto-request-chat-header input:focus,
.iauto-request-chat-header input:active {
    box-shadow: transparent;
    margin-right: 10px;
    width: 250px;
    border-top: none;
    border-left: 0;
    border-right: 0;
}