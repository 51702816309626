.iauto-operator-offer-panel {
    background-color: #ffffff;
    border-radius: 10px;
    margin-right: 10px;
    flex-direction: column;
    display: flex;
    padding-bottom: 10px;
}

.iauto-operator-offer-panel-header{
    text-align: center;
    font-size: 15px;
    font-weight: bold;
}

@media (max-width: 600px) {
    .iauto-avail-requests-wrapper {
        margin: 10% 0 ;
    }
}