.iauto-offer-message-wrapper {
    border: 1px solid #e1e0e0;
    margin: 10px 0;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.iauto-offer-message-wrapper-refused {
    border: 1px solid #e1e0e0;
    margin: 10px 0;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    opacity: 0.35;
}

.iauto-offer-message-wrapper-accepted {
    border: 1px solid #e1e0e0;
    margin: 10px 0;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.iauto-offer-table-header {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 20px 10px;
    border-bottom: 1px solid #e8e8e8;
}

.offer-title {
    font-weight: 400;
    font-size: 14px;
}

.offer-footer-actions {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}

.iauto-message-offer .ant-table-thead > tr > th {
    background-color: #fafafa;
    text-align: left;
    font-weight: bold;
    padding: 10px;
}

.iauto-message-offer .ant-table-tbody > tr > td {
    padding: 10px;
}

.desktop-table {
    display: block;
}
.mobile-cards {
    display: none;
}

.offer-card {
    margin-bottom: 16px;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    overflow: hidden;
}

@media (max-width: 768px) {
    .desktop-table {
        display: none;
    }
    .mobile-cards {
        display: block;
    }
    .offer-card {
        width: 100%;
    }
}
