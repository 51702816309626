.iauto-requests-menu .ant-menu-inline,
.iauto-requests-menu .ant-menu-vertical,
.iauto-requests-menu .ant-menu-vertical-left {
    border-right: none;
}

.iauto-requests-menu {
    padding: 0 0 0 10px;
}

@media (max-width: 600px) {
    .iauto-requests-menu {
        margin-top:5%;
        padding: 0;
    }
}

.iauto-request-menu-loader {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    margin-left: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}