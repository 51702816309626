/*messsage styles*/
.iauto-message-in {
  display: flex;
  align-items: baseline;
  margin: 2% 3%;
}

.iauto-message-out {
  display: flex;
  align-items: baseline;
  margin-left: auto;
  margin-right: 3%;
  margin-bottom: 2%;
}

.iauto-message-content-left {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: baseline;
}

.iauto-message-content-right {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-end;
}

.iauto-message-step {
  margin: 1% 2% 2% 2%;
  background-color: #2a2a2a;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 3px;
}

.iauto-message-offer {
  background-color: #ffffff;
  border-radius: 5px;
}

.iauto-message-in-text {
  padding: 5px 10px;
  margin: 5px;
  border-radius: 8px;
  color: #ffffff;
  display: inline;
  white-space: pre-line;
  background-color: #3b3b3b;
}

.iauto-message-out-text {
  padding: 5px 10px;
  margin: 5px;
  border-radius: 8px;
  display: inline;
  white-space: pre-line;
  color: #ffffff;
  background-color: #7b7a7a;
}

.iauto-message-in-offer {
  margin: 5px;
  border-radius: 8px;
  display: inline;
  white-space: pre-line;
}

.iauto-message-out-offer {
  margin: 5px;
  border-radius: 8px;
  display: inline;
  white-space: pre-line;
}

.iauto-message-from-images {
  margin-left: 45px;
}

.iauto-message-to-images {
  margin-right: 45px;
  margin-left: auto;
}

.iauto-message-image-text {
  margin: 5px 0 0 0;
  padding: 5px 10px;
  background-color: #2f2f2f;
  border-radius: 0 0 8px 8px;
  color: #ffffff;
}
