.user-profile-row {
    margin-top: 20px;
}

.user-profile-title {
    text-align: center;
}

.iauto-user-profile-wrapper {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-profile-descriptions {
    margin-bottom: 20px;
}

.user-profile-actions {
    text-align: center;
    margin-top: 20px;
}

.save-button {
    margin-right: 10px;
}

.cancel-button, .edit-button {
    margin-top: 10px;
}
