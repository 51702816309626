/*avatar styles*/
.iauto-message-avatar-container {
    display: inline-block;
    text-align: center;
    margin: 0 5px;
}

.iauto-message-avatar-container h4 {
    margin: 0 5px;
}

.iauto-operator-avatar,
.iauto-client-avatar {
    margin: 0 10px;
}