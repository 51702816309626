/*base layout styles*/
html,
body {
    height: 100%;
    margin: 0;
    background-color: #f0f2f5;
}

.app {
    text-align: center;
}

#root {
    height: 100%;
}

/*header*/
.iauto-header-items
    > .ant-menu-dark.ant-menu-horizontal
    > .ant-menu-item:hover {
    background-color: #515558 !important;
}

/***************************found cars list*****************************/
.iauto-car-list-wrapper {
    margin-top: 30px;
}

/***************************search block*****************************/

.iauto-main-page-container {
    align-items: center;
}

.iauto-input-wrapper {
    margin: auto;
    margin-top: 50px;
    display: inline-flex !important;
}

.iauto-vin-input,
.iauto-vin-input:hover,
.iauto-vin-input:focus {
    padding-right: 15px;
    margin-right: 15px;
    border-bottom: solid 1px #001529;
}

.iauto-vin-input::placeholder {
    color: #636565;
}

.iauto-vin-input > input {
    color: #001529 !important;
}

.iauto-decode-button {
    color: #ffffff;
    background-color: #001529;
}

.ant-upload-list .ant-upload-list-text,
.ant-upload-list-text-container {
    display: none !important;
}

.iauto-loader-container {
    width: 100%;
    text-align: center;
    margin-top: 50px;
}

.iauto-loader-container .ant-spin-show-text {
    color: #606d7a;
}

.iauto-loader-container .ant-spin-dot-item {
    background-color: #454f59;
}

/************************search page*********************************/
.ant-timeline-item-tail {
    border-left: 2px solid #a4a2a2;
}

.iauto-search-history-space {
    width: 100%;
}

.iauto-search-history-car-list-wrapper {
}

.iauto-search-collapse .ant-collapse-item .ant-collapse-header {
    text-overflow: ellipsis;
}

.iauto-search-collapse-header {
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
}

.iauto-add-comment-btn {
    color: #ffffff;
    background-color: #001529;
    border: none;
    margin-right: 10px;
}

.iauto-close-comment-block-btn:hover {
    background-color: #dcdcdc;
    color: #777676;
    border-color: #b9b9b9;
}

.iauto-add-comment-btn:hover {
    color: #ffffff;
    background-color: #4b5d6e;
    border: none;
}

.iauto-open-edit-btn {
    margin-top: 15px;
}

.iauto-open-edit-btn:hover {
    color: #ffffff;
    background-color: #4b5d6e;
    border: none;
}

.iauto-add-comment-block-wrapper {
    margin-top: 25px;
}

.iauto-add-comment-block-wrapper .ant-input:hover {
    border-color: #acb0b2;
}

/***********************common cpmponents***************/
.iauto-text-input,
.iauto-text-input:hover,
.iauto-text-input:focus {
    margin: 15px;
    border-bottom: solid 1px #001529;
}

.iauto-text-input::placeholder {
    color: #636565;
}

.iauto-text-input > input {
    color: #001529 !important;
}
